import React, { Component, Fragment } from 'react';

import { get } from '../../../util/requests';

import BotArmyList from './BotArmyList';

import './BotArmy.scss';
import BotHelper from '../../../util/botHelper'

class BotArmy extends Component {
  state = {
    buidlArmy: [],
    supportArmy: [],
    generations: [],
    totalSupports: undefined
  };

  componentDidMount = async () => {
    const { data } = await get(`bots`);
    this.setState({
      ...this.sortArmy(data),
      totalSupports: BotHelper.totalSupports(this.props.primeBot.tokenId, data)
    });
  };

  sortArmy = (data) => {
    const buidlArmy = [];
    const supportArmy = [];
    const primes = [];

    data.forEach((bot) => {
      let belongsTo = bot.relatedPrimeBot === this.props.primeBot.tokenId;

      if (!bot.disabled && bot.tokenType === 'buidl' && belongsTo) {
        buidlArmy.push(bot);
      }

      if (!bot.disabled && bot.tokenType === 'support' && belongsTo) {
        supportArmy.push(bot);
      }

      if (!bot.disabled && bot.tokenType === 'prime') {
        primes.push(bot);
      }
    });

    const primeDna = this.props.primeBot.dna.split('-');
    primeDna.shift();
    const primeDnaString = primeDna.join();
    const rawGenerations = primes.filter((bot) => {
      const dna = bot.dna.split('-');
      dna.shift();
      const dnaString = dna.join();
      return (
        primeDnaString === dnaString &&
        bot.tokenId !== this.props.primeBot.tokenId
      );
    });

    const generations = rawGenerations.sort((a, b) => {
      return a.generation - b.generation;
    });

    return { buidlArmy, supportArmy, generations };
  };

  render() {
    const { buidlArmy, supportArmy, generations, totalSupports } = this.state;

    return (
      <div className="Contain">
        {generations.length ? (
          <Fragment>
            <h3>Generations ({generations.length})</h3>
            <BotArmyList army={generations} />
          </Fragment>
        ) : null}

        <h3>Support Bots ({supportArmy.length}) Total of all generations ({totalSupports})</h3>
        <BotArmyList army={supportArmy} />
        {supportArmy.length < 1 && (
          <p>No Support Bots have been generated for this repo.</p>
        )}
        <h3>Buidl Bots ({buidlArmy.length})</h3>
        <BotArmyList army={buidlArmy} />
        {buidlArmy.length < 1 && (
          <p>No Buidl Bots have been generated by this repo.</p>
        )}
      </div>
    );
  }
}

export default BotArmy;
